import { useMutation } from 'react-query';

import { AuthAPI } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';

import { VerifyPasswordResponse } from '../types';

export type ChangePasswordDTO = {
  password: string;
  passwordConfirmation: string;
  uid: string;
  token: string;
};

export const changePassword = (data: ChangePasswordDTO): Promise<VerifyPasswordResponse> => {
  return axios.post(AuthAPI.setPassword, {
    new_password1: data.password,
    new_password2: data.passwordConfirmation,
    uid: data.uid,
    token: data.token,
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword);
};
