import { XIcon } from '@heroicons/react/outline';

import { Dialog } from '@/components/Elements';
import { useModalStore } from '@/stores/modal';
import { decimalFormatter } from '@/utils/decimalFormatter';
import { reverseCamel } from '@/utils/snakeCaseConverter';

const Row = ({ rowKey, value }: { rowKey: string; value: any }) => {
  return (
    <div className="flex leading-loose hover:bg-gray-100">
      <div className="p-2 w-min flex-shrink" style={{ flex: 2 }}>
        {reverseCamel(rowKey)}:
      </div>
      <div
        className="p-2 text-center border-l border-gray-200 text-gray-500 text-sm"
        style={{ flex: 4 }}
      >
        {decimalFormatter(value, 3)}
      </div>
    </div>
  );
};

export const DataPointPopupModal = () => {
  const { hideModal, modalProps } = useModalStore();

  return (
    <Dialog isOpen={true} onClose={hideModal}>
      <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-24  min-w-min w-1/2 sm:align-middle ">
        <div className="bg-dark-blue flex flex-row justify-between items-center text-xl py-3 px-4 text-white font-bold">
          Data Point Info
          <button
            onClick={hideModal}
            className="bg-white focus:outline-none bg-opacity-40 p-1 rounded-full cursor-pointer"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        <div
          className="overflow-y-auto rounded-lg flex flex-col bg-white p-6 "
          style={{ maxHeight: '60vh' }}
        >
          <Row rowKey={'technicalValue'} value={modalProps.data.payload['technicalValue']} />
          {Object.keys(modalProps.dataPoint).map((key: any, index: number) => (
            <Row rowKey={key} value={modalProps.dataPoint[key]} key={index} />
          ))}
        </div>
      </div>
    </Dialog>
  );
};
