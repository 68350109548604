import { CircularProgress, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 7,
    borderRadius: 5,
    background: '#EDF2F6',
  },
  bar: {
    borderRadius: 5,
    background: '#FF4F16',
  },
});

const Loader = ({ progress = 0, fileName = '' }) => {
  const classes = useStyles();
  return (
    <>
      <div className="flex mx-auto w-3/4 h-24 bg-white rounded-xl mt-40 relative">
        <div className="flex flex-row absolute mt-6 space-x-6 ml-6">
          <CircularProgress color="secondary" size={24} />
          <p>File {fileName} is uploading...</p>
        </div>
        <div className="absolute mt-16 ml-6 w-full pr-10">
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{ root: classes.root, bar: classes.bar }}
          />
        </div>
      </div>
    </>
  );
};

export default Loader;
