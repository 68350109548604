import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { DataLoadApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { useSideBar } from '@/stores/sideBar';

import { verifyTable } from '../types';

export interface UseMutationVerifyTableVariables {
  analysisId: string;
  columnType: { column_name: string; column_type: string }[];
}

export const verifyTableType = ({
  analysisId,
  columnType,
}: UseMutationVerifyTableVariables): Promise<verifyTable> => {
  return axios.put(DataLoadApi.fetchRows + `${analysisId}`, {
    column_type_map_list: columnType,
  });
};

type verifyTableOptions = {
  config?: MutationConfig<typeof verifyTableType>;
  analysisId: string;
};

export const useVerifyTable = ({ config, analysisId }: verifyTableOptions) => {
  const { addNotification } = useNotificationStore();
  const { resetLinks } = useSideBar();

  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      resetLinks();
      navigate(`/regression-analysis/${analysisId}`, { replace: true });
      addNotification({
        type: 'success',
        title: 'File successfully submitted',
      });
    },
    onError: (error) => {
      //TODO
      // Not sure why we are getting 403 from the backend, most probably due to GCP permissions error.
      if (error.response?.status == 500)
        addNotification({
          type: 'error',
          title: error.message,
        });
    },
    mutationFn: verifyTableType,
    ...config,
  });
};
