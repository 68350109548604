import * as React from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="flex py-6 flex-1 overflow-x-hidden">
        <div className="flex flex-1 max-w-full px-1 sm:px-6 md:px-8">{children}</div>
      </div>
    </>
  );
};
