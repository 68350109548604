import camelCase from 'lodash/camelCase';

export function reverseCamel(camelStr: string) {
  return camelStr
    .split(/(?=[A-Z])/)
    .map(function (p) {
      return p.charAt(0).toUpperCase() + p.slice(1);
    })
    .join(' ');
}

export function keysToCamel(snakeCaseJson: any): any {
  if (
    snakeCaseJson === Object(snakeCaseJson) &&
    !Array.isArray(snakeCaseJson) &&
    typeof snakeCaseJson !== 'function'
  ) {
    const n: { [key: string]: any } = {};
    Object.keys(snakeCaseJson).forEach((key: string) => {
      n[camelCase(key)] = keysToCamel(snakeCaseJson[key]);
    });
    return n;
  } else if (Array.isArray(snakeCaseJson)) {
    return snakeCaseJson.map((i) => {
      return keysToCamel(i);
    });
  }
  return snakeCaseJson;
}
