import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import withAnalysisCheck from '@/providers/withAnalysisCheck';

import { BrowseFile } from './browseFile';

const UpdateDataLoad = () => {
  let { analysisId } = useParams();
  analysisId = analysisId || '';

  return (
    <ContentLayout title="">
      <BrowseFile analysisId={analysisId} />
    </ContentLayout>
  );
};

export default withAnalysisCheck(UpdateDataLoad);
