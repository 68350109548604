const storagePrefix = 'assortment_analysis_';
const storageSessionKey = 'remember_me';

export enum Token {
  accessTokenKey = 'access_token',
  refreshTokenKey = 'refresh_token',
}

const storage = {
  getToken: (key: Token) => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token_${key}`) as string);
  },
  setToken: (token: string, key: Token) => {
    window.localStorage.setItem(`${storagePrefix}token_${key}`, JSON.stringify(token));
  },
  setSession: (session: boolean) => {
    window.localStorage.setItem(`${storagePrefix}${storageSessionKey}`, JSON.stringify(session));
  },
  getSession: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}${storageSessionKey}`) as string
    );
  },
  clearAllTokens: () => {
    Object.values(Token).forEach((key: string) => {
      window.localStorage.removeItem(`${storagePrefix}token_${key}`);
    });
  },
};

export default storage;
