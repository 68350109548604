import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Cell, Row, TableCommonProps } from 'react-table';

import { Spinner } from '@/components/Elements';
import Loader from '@/components/Elements/Spinner/Backdrop';

import { useFetchRows } from '../api/getRows';
import { useVerifyTable } from '../api/verifyTableType';
import { Dropdown, dropdownValuesMap } from '../components/dropDown';
import { ListToString } from '../routes/browseFile';

import DataLoadTable from './DataLoadTable';

const HeaderClassName = 'border px-6 py-3 text-xs font-medium tracking-wider text-left';
const dropdownKeys = Object.keys(dropdownValuesMap);

const generateAPICallData = (columnsType: string[], data: any[]) => {
  const keys = _.keys(data[0]);
  const payLoad = columnsType.map((col: string, index: number) => {
    return {
      column_name: keys[index],
      column_type: dropdownValuesMap[columnsType[index]],
    };
  });
  return payLoad;
};

const getColDataType = (col: string, numericColumns: string[]) => {
  return numericColumns.indexOf(col) === -1 ? dropdownKeys[0] : dropdownKeys[1];
};

const DataLoad = ({ analysisId, setShowTable }: any) => {
  const fetchRows = useFetchRows();
  const checkHeaderType = useVerifyTable({ analysisId });
  const [top_data, setRow] = useState([]);
  const [top_column, setColumn] = useState([]);
  const [dropDownState, setDropDownState] = useState<string[] | []>([]);

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    const parentCell = e.target.parentNode as HTMLTableCellElement;
    const cell = parentCell.cellIndex;
    const index = e.target.selectedIndex;
    const value = e.target.options[index].text;
    setDropDownState((prevData) => {
      const newArr = [...prevData];
      newArr[cell] = value;
      return newArr;
    });
  };

  const getColumns = (firstRow: any[], columnType: string[]): any[] => {
    const keys = _.keys(firstRow);
    const columns = keys.map((col: string, index: number) => {
      return {
        Header: col,
        columns: [
          {
            Header:
              col.toLowerCase() === 'price' ? (
                'Numeric'
              ) : (
                <Dropdown selected={columnType[index]} callback={handleDropdownChange} />
              ),
            accessor: col,
            className: HeaderClassName + ' bg-light-grey',
          },
        ],
        className: HeaderClassName + ' bg-light-black text-white',
      };
    });
    return columns;
  };

  const ColumnsDataType = (firstRecord: string[], numericColumns: string[]) => {
    return firstRecord.map((col: string) => {
      return col.toLowerCase() === 'price' ? 'Numeric' : getColDataType(col, numericColumns);
    });
  };

  const getData = async () => {
    const { data } = await fetchRows.mutateAsync({ analysisId });
    const firstRecord = _.keys(data.row_details[0]);
    const columnType = ColumnsDataType(firstRecord, data.numeric_columns);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    setRow(data.row_details);
    setDropDownState(columnType);
  };

  const validateFile = async (e: any) => {
    e.preventDefault();
    const headerType = generateAPICallData(dropDownState, top_data);
    await checkHeaderType.mutateAsync({ analysisId: analysisId, columnType: headerType });
  };

  useEffect(() => {
    getData().then();
  }, []);

  useEffect(() => {
    const updatedColumns = getColumns(top_data[0], dropDownState);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    setColumn(updatedColumns);
  }, [dropDownState]);

  if (fetchRows.isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  {
    /* heading and Error Message */
  }
  const header = (
    <div className="flex flex-row mb-6 mt-2 justify-between">
      <div>
        <p className="text-2xl font-bold font-sans-serif">Data Load</p>
        <p className="text-sm">
          Showing {fetchRows.isError ? 0 : 10} rows out of{' '}
          {fetchRows.isIdle ? 0 : fetchRows.data?.data.no_of_rows ?? 0}
        </p>
      </div>
      <div className="max-w-4xl">
        <p className="text-red-600 text-xs">
          <pre className="break-all">
            {checkHeaderType.isError
              ? ListToString(checkHeaderType.error.response?.data.error_msg)
              : ''}
          </pre>
        </p>
      </div>
    </div>
  );

  {
    /* Buttons */
  }
  const footer = (
    <div className="flex py-4 space-x-6 justify-end">
      <button
        className="text-small drop-shadow-2xl text-white rounded-full bg-create-green hover:drop-shadow-xl py-2 px-16 disabled:pointer-events-none disabled:bg-button-disabled
disabled:drop-shadow-none"
        onClick={(e) => validateFile(e)}
      >
        OK
      </button>
      <button
        className="text-small border border-black text-black rounded-full bg-transparent py-2 px-12"
        onClick={(e) => {
          e.preventDefault();
          setShowTable(false);
        }}
      >
        Cancel
      </button>
    </div>
  );

  return (
    <>
      {checkHeaderType.isLoading ? <Loader /> : null}
      <div className="flex flex-1 flex-col mt-2 max-w-full">
        {header}
        <DataLoadTable
          columns={top_column}
          data={top_data}
          getRowProps={(row: Row): TableCommonProps => ({
            style: {
              backgroundColor: row.index % 2 === 0 ? 'white' : '#EDF2F6',
            },
          })}
          getCellProps={(cellInfo: Cell): TableCommonProps => ({
            style: {
              // TODO: Keep it in state if we are getting in API.
              color: [''].includes(cellInfo.column.id.toLowerCase()) ? 'red' : 'gray',
            },
          })}
        />
        {footer}
      </div>
    </>
  );
};
export default DataLoad;
