//TODO generate color from company name
export const scatterGraphColors = [
  '#2f4f4f',
  '#556b2f',
  '#8b4513',
  '#6b8e23',
  '#800000',
  '#191970',
  '#006400',
  '#708090',
  '#3cb371',
  '#bc8f8f',
  '#bdb76b',
  '#4682b4',
  '#d2691e',
  '#9acd32',
  '#20b2aa',
  '#cd5c5c',
  '#00008b',
  '#32cd32',
  '#daa520',
  '#7f007f',
  '#8fbc8f',
  '#9932cc',
  '#ff0000',
  '#ff8c00',
  '#0000cd',
  '#4169e1',
  '#dc143c',
  '#00bfff',
  '#f4a460',
  '#0000ff',
  '#a020f0',
  '#adff2f',
  '#ff6347',
  '#da70d6',
  '#d8bfd8',
  '#ff00ff',
  '#db7093',
  '#ffff54',
  '#6495ed',
  '#dda0dd',
  '#90ee90',
  '#add8e6',
  '#ff1493',
  '#7fffd4',
  '#ffdead',
  '#ff69b4',
];
