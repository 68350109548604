import React from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

const schema = z.object({
  username: z.string().min(1, 'Please enter a valid username'),
  password: z.string().min(1, 'Please enter the correct password'),
});

type LoginValues = {
  username: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();

  const onSubmit = async (values: LoginValues) => {
    await login(values);
    onSuccess();
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <Form<LoginValues, typeof schema>
        onSubmit={onSubmit}
        schema={schema}
        className="w-96 lg:w-[620px]"
      >
        {({ register, formState, setValue }) => (
          <>
            <InputField
              type="text"
              placeholder="Username"
              error={formState.errors['username']}
              registration={register('username')}
              className="shadow-right"
              change={(event) =>
                setValue('username', event.target.value, {
                  shouldTouch: true,
                })
              }
            />
            <InputField
              type="password"
              placeholder="Password"
              error={formState.errors['password']}
              registration={register('password')}
              className="shadow-right"
              /*
              NOTE hack since input was not getting set as touched when using
              enter key since focus was still on the input thus causing validation error when using
              enter key to submit
              */
              change={(event) =>
                setValue('password', event.target.value, {
                  shouldTouch: true,
                })
              }
            />
            <div className="flex w-full justify-center mt-4 ">
              <Button
                isLoading={isLoggingIn}
                type="submit"
                variant="custom"
                className="mt-6 w-60 py-3.5 text-white font-bold text-lg rounded-full bg-cta shadow-right"
              >
                Sign In
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-3 flex items-center justify-end">
        <div className="text-sm">
          <Link
            to="../reset-password"
            className="font-medium text-gray-700 hover:text-cta underline"
          >
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};
