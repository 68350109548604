import { useNavigate } from 'react-router-dom';

import { Layout } from '@/components/Layout';

import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Sign In To Your Account" description="Enter your details to get started">
      <LoginForm onSuccess={() => navigate('/')} />
    </Layout>
  );
};
