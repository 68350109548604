import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useMemo } from 'react';
import { GiEmptyMetalBucketHandle } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { MODAL_TYPES, useModalStore } from '@/stores/modal';
import { dateTime, formatDate } from '@/utils/format';

import { useDeleteAnalysis } from '../api/deleteAnalysis';
import { useAnalysisList } from '../api/getListData';
import { CreateNew } from '../components/CreateNew';
import { Table } from '../components/Table';

const Heading = () => <h3 className="text-center text-3xl font-medium text-gray-900">Analysis</h3>;

export const AssortmentAnalysisHome = () => {
  const deleteAnalysis = useDeleteAnalysis();
  const analysisList = useAnalysisList();
  const navigate = useNavigate();
  let component: JSX.Element = <></>;

  const { showModal, hideModal } = useModalStore();

  const editAnalysisHandler = (id: string) => {
    navigate(`/data-load/${id}`);
  };

  const deleteAnalysisHandler = (id: string) => {
    showModal({
      modalType: MODAL_TYPES.DELETE_ANALYSIS_MODAL,
      modalProps: {
        deleteCB: deleteCB.bind(null, id),
      },
    });
  };

  const deleteCB = async (id: string) => {
    hideModal();
    await deleteAnalysis.mutateAsync({ analysisId: id });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Analysis Name',
        accessor: 'analysisName',
        Cell: ({ cell: { value } }: any) => (
          <span
            data-for="analysis-name"
            data-tip={value}
            className="whitespace-nowrap overflow-ellipsis overflow-hidden "
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription',
        Cell: ({ cell: { value } }: any) => (
          <span
            data-for="analysis-description"
            data-tip={value}
            style={{ cursor: 'default' }}
            className="whitespace-nowrap overflow-ellipsis overflow-hidden "
          >
            {value}
          </span>
        ),
        width: 300,
      },
      {
        Header: 'Date Created',
        accessor: 'createdDate',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden px-3">
              {formatDate(value)}
            </span>
          );
        },
      },
      {
        Header: 'Date Modified',
        accessor: 'modifiedDate',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden px-3">
              {dateTime(value)}
            </span>
          );
        },
        width: 200,
      },
      {
        Header: '',
        accessor: 'analysisId',
        Cell: function order(props: any) {
          return (
            <div className="flex">
              <button
                className="focus:outline-none ml-4"
                onClick={() => editAnalysisHandler(props.value)}
              >
                <PencilAltIcon className="w-5 h-5" />
              </button>
              <button
                className="focus:outline-none ml-4"
                onClick={() => deleteAnalysisHandler(props.value)}
              >
                <TrashIcon className="outline-none w-5 h-5" />
              </button>
            </div>
          );
        },
        width: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData = useMemo(
    () => (analysisList.isLoading || analysisList.isIdle ? Array(6).fill({}) : analysisList.data),
    [analysisList.data, analysisList.isLoading]
  );

  const tableColumns = useMemo(
    () =>
      analysisList.isLoading || analysisList.isIdle
        ? columns.map((column) => ({
            ...column,
            Cell: (
              <div className="flex-1 animate-pulse">
                <div className="h-8 bg-gray-200 mt-3 mb-6 rounded"></div>
              </div>
            ),
          }))
        : columns,
    [analysisList.data, analysisList.isLoading]
  );
  if (!tableData || tableData?.length === 0) {
    // console.log(data?.length);
    component = (
      <div className="bg-white text-light-black h-80 flex justify-center items-center flex-col rounded-3xl text-lg border-dashed transition duration-200 hover:shadow-2xl">
        <GiEmptyMetalBucketHandle className="h-12 w-12" />
        <h4 className="mt-2">No Analysis Found</h4>
      </div>
    );
  }
  if (tableData?.length) component = <Table columns={tableColumns} data={tableData} />;

  return (
    <ContentLayout title="Analysis List">
      <div className="overflow-x-hidden overflow-y-hidden h-min flex flex-1 flex-col mt-6 overflow-y-auto rounded-xl ">
        <div className="flex flex-row justify-between items-center mb-6">
          <Heading />
          <CreateNew />
        </div>
        {component}
      </div>
    </ContentLayout>
  );
};
