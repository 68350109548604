import create from 'zustand';

type TableSearchStore = {
  searchString: string;
  setSearchString: (word: string) => void;
  clearString: () => void;
};

export const useTableSearch = create<TableSearchStore>((set) => ({
  searchString: '',
  setSearchString: (word) =>
    set({
      searchString: word,
    }),
  clearString: () =>
    set({
      searchString: '',
    }),
}));
