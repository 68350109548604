import { Navigate, Route, Routes } from 'react-router-dom';

import UpdateDataLoad from './dataLoad';

export const DataLoadRoutes = () => {
  return (
    <Routes>
      <Route path=":analysisId" element={<UpdateDataLoad />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
