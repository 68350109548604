import { useNetwork } from '@/hooks/useNetwork';
import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

function App() {
  useNetwork();
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
