import { SelectorIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HeaderGroup,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { useTableSearch } from '@/stores/tableSearch';

// import { Overflow } from './CustomScrollBar';

const rowSize = 65; //in Px

const SortIcon = ({ column }: { column: any }) => {
  let icon: JSX.Element;

  if (column.isSorted) {
    if (column.isSortedDesc) {
      icon = <SortDescendingIcon className="h-5 w-5" />;
    } else {
      icon = <SortAscendingIcon className="h-5 w-5" />;
    }
  } else {
    icon = <SelectorIcon className="h-5 w-5" />;
  }

  return icon;
};

const RenderHeader = ({ headerGroups }: { headerGroups: HeaderGroup<any>[] }): JSX.Element => {
  const isNotLastColumn = useCallback(
    ({ index, headerGroup }: { index: number; headerGroup: HeaderGroup<any> }) => {
      return index != headerGroup.headers.length - 1;
    },
    [headerGroups]
  );

  return (
    <>
      {headerGroups.map((headerGroup, outerIndex: number) => (
        <div {...headerGroup.getHeaderGroupProps()} key={outerIndex} className="h-16">
          {headerGroup.headers.map((column: any, index: number) => (
            <div
              {...column.getHeaderProps(
                isNotLastColumn({ index, headerGroup }) ? { ...column.getSortByToggleProps() } : {}
              )}
              key={index}
              className="flex justify-between items-center border border-white border-r-1 border-b-0 px-3"
            >
              {column.render('Header')}
              <span>
                {isNotLastColumn({ index, headerGroup }) ? <SortIcon column={column} /> : null}
              </span>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

function fuzzyTextFilterFn(rows: any, id: string[], filterValue: any) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id[0]]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val;

export function Table({ columns, data }: any) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const navigate = useNavigate();

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useFlexLayout,
      useFilters,
      useGlobalFilter
    );

  const { searchString } = useTableSearch();

  useEffect(() => {
    setGlobalFilter(searchString);
  }, [searchString]);

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className={`min-h-[65px] bg-white last:rounded-b-xl last:pb-2 first:pt-2`}
        >
          {row.cells.map((cell, innerIndex) => {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                {...cell.getCellProps()}
                className={`flex  max-h-[65px] py-2 items-center first:ml-2 last:mr-2 last:justify-end px-3 ${
                  index % 2 == 0 ? 'bg-light-blue first:rounded-l-xl last:rounded-r-xl' : 'bg-white'
                } ${innerIndex == 0 ? 'hover:text-light-green cursor-pointer' : ''}`}
                key={innerIndex}
                onClick={() => {
                  if (innerIndex == 0) navigate(`/data-load/${row.values.analysisId}`);
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <div
      {...getTableProps({
        style: { height: '100%', maxHeight: '100%', flexBasis: '100%' },
      })}
      className="flex flex-col min-h-0 overflow-hidden table-auto  pb-2 border-gray-200 rounded-xl"
    >
      <div className="bg-dark-blue rounded-t-xl text-white">
        <RenderHeader headerGroups={headerGroups} />
      </div>
      <div
        {...getTableBodyProps({
          style: {
            height: `100%`,
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        })}
      >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={rows.length}
              itemSize={rowSize}
              width={width}
              // outerElementType={Overflow}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
        <ReactTooltip
          id="analysis-description"
          place="top"
          type="dark"
          effect="solid"
          className="break-all break-words block max-w-4xl min-w-min"
          multiline={true}
          bodyMode={true}
        />
        <ReactTooltip
          id="analysis-name"
          place="top"
          type="dark"
          effect="solid"
          className="break-all break-words block max-w-4xl min-w-min"
          multiline={true}
          bodyMode={true}
        />
      </div>
    </div>
  );
}
