import { XIcon } from '@heroicons/react/outline';
import * as React from 'react';

import { Button, Dialog } from '@/components/Elements';
import { useModalStore } from '@/stores/modal';

export const DeleteConfirmationModal = () => {
  const { hideModal, modalProps } = useModalStore();

  return (
    <Dialog isOpen={true} onClose={hideModal}>
      <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-min w-full ">
        <div className="bg-dark-blue flex flex-row justify-between items-center text-xl py-3 px-4 text-white font-bold">
          Delete Analysis
          <button
            onClick={hideModal}
            className="bg-white focus:outline-none bg-opacity-40 p-1 rounded-full cursor-pointer"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center mt-6 mx-20 py-4 px-6">
          <div className="text-xl text-center w-max">
            Are you sure you want to delete this analysis?
          </div>
          <div className="flex w-full justify-center mt-4 ">
            <Button
              onClick={hideModal}
              variant="custom"
              className="mr-1 ml-6 mt-6 w-48 py-3 text-black font-semibold text-lg rounded-full border-2 border-gray-500 bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                modalProps.deleteCB();
              }}
              type="submit"
              variant="custom"
              className="ml-1 mr-6 mt-6 w-48 py-3 text-white font-semibold text-lg rounded-full bg-cta"
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
