import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | string;
  className?: string;
  change?: (event: React.ChangeEvent<any>) => void;
  registration: Partial<UseFormRegisterReturn>;
  maxLength?: number;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    placeholder,
    labelClassName,
    className,
    change,
    registration,
    error,
    icon,
    maxLength,
  } = props;

  return (
    <FieldWrapper error={error} label={label} labelClassName={labelClassName} icon={icon}>
      <input
        type={type}
        placeholder={placeholder}
        className={clsx(
          'rounded-3xl appearance-none block w-full px-6 py-3 border border-gray-50 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500  sm:text-base',
          className
        )}
        {...registration}
        onChange={change}
        maxLength={maxLength}
      />
    </FieldWrapper>
  );
};

export const TextAreaField = (props: InputFieldProps) => {
  const {
    label,
    placeholder,
    labelClassName,
    className,
    change,
    registration,
    error,
    icon,
    maxLength,
  } = props;

  return (
    <FieldWrapper error={error} label={label} labelClassName={labelClassName} icon={icon}>
      <textarea
        placeholder={placeholder}
        className={clsx(
          'rounded-3xl appearance-none block w-full px-6 py-3 border border-gray-50 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500  sm:text-base',
          className
        )}
        {...registration}
        onChange={change}
        maxLength={maxLength}
      />
    </FieldWrapper>
  );
};
