import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';

import { useChangePassword } from '../api/changePassword';

const schema = z
  .object({
    password: z
      .string()
      .regex(new RegExp(/(\w.{6,18}\w)/), 'Password must be between 6 and 18 characters')
      .regex(new RegExp(/(?=.*\d)/), 'Password must contain at least one number')
      .regex(
        new RegExp(/(?=.*[A-Z])(?=.*[a-z])/),
        'Password must contain at least one uppercase and lowercase letter'
      )
      .regex(new RegExp(/(?=.*\W)/), 'Password must contain at least one special character'),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password == data.passwordConfirmation, {
    message: "Passwords don't match",
    path: ['passwordConfirmation'], // path of error
  });

export type ChangePasswordValues = {
  password: string;
  passwordConfirmation: string;
};

type HelperProps = { text: string };

type ChangePasswordFormProps = {
  uid: string;
  token: string;
};

const HelperText = ({ text }: HelperProps) => {
  return (
    <div role="note" aria-label={text} className="mt-3 mb-3 ml-6 text-sm text-gray-500">
      {text}
    </div>
  );
};

export const ChangePasswordForm = ({ uid, token }: ChangePasswordFormProps) => {
  const changePassword = useChangePassword();
  const navigate = useNavigate();

  const onSubmit = async (values: ChangePasswordValues) => {
    await changePassword.mutateAsync({ ...values, uid, token });

    showSuccessNotification();
    navigate('/', { replace: true });
  };

  const showSuccessNotification = () => {
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'Password Changed Successfully',
    });
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <Form<ChangePasswordValues, typeof schema>
        onSubmit={onSubmit}
        schema={schema}
        className="w-96 lg:w-[620px]"
      >
        {({ register, formState, clearErrors }) => (
          <>
            <InputField
              type="password"
              placeholder="New Password"
              error={formState.errors['password']}
              registration={register('password')}
              className="shadow-right"
              change={() => clearErrors(['password', 'passwordConfirmation'])}
            />
            <HelperText
              text="Password must be complex having at-lest 1 upper case, 1 lower case, 1 numeric, 1 special character. 
            It should not be common or slang word."
            />
            <InputField
              type="password"
              placeholder="New Password (again)"
              error={formState.errors['passwordConfirmation']}
              registration={register('passwordConfirmation')}
              className="shadow-right"
              change={() => clearErrors('passwordConfirmation')}
            />
            <div className="flex w-full justify-center mt-4 ">
              <Button
                isLoading={changePassword.isLoading}
                type="submit"
                variant="custom"
                className="mt-6 w-68 py-3.5 text-white font-bold text-lg rounded-full bg-cta shadow-right"
              >
                Change Password
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
