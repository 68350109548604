import { useMutation } from 'react-query';

import { AuthAPI } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';

import { ResetPasswordResponse } from '../types';

export type ResetPasswordDTO = {
  email: string;
};

export const resetPassword = (data: ResetPasswordDTO): Promise<ResetPasswordResponse> => {
  return axios.post(AuthAPI.resetPassword, data);
};

export const useResetPassword = () => {
  return useMutation(resetPassword);
};
