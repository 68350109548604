import { PencilIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { useModalStore } from '@/stores/modal';

import { useAddAnalysis } from '../api/addAnalysis';

const schema = z.object({
  name: z.string().min(1, 'Please enter a name').max(255, 'Name must be less than 255 characters'),
  description: z.string().max(200, 'Description must be less than 200 characters'),
});

export type CreateAnalysisValue = {
  name: string;
  description: string;
};

const InputIcon = ({ className }: { className?: string }) => {
  return (
    <PencilIcon
      className={clsx(
        'stroke-current text-gray-400 pointer-events-none translate-y-1/2 w-4 h-4 absolute top-1/2 transform right-3',
        className
      )}
    />
  );
};
export const CreateAnalysisForm = () => {
  const addAnalysis = useAddAnalysis();

  const { hideModal } = useModalStore();

  const onSubmit = async (values: CreateAnalysisValue) => {
    await addAnalysis.mutateAsync({ name: values.name, description: values.description });
  };

  return (
    <div className="flex flex-col items-center justify-center py-4 px-4 mx-12 mb-6">
      <Form<CreateAnalysisValue, typeof schema>
        onSubmit={onSubmit}
        schema={schema}
        className="w-96 lg:w-[620px]"
      >
        {({ register, formState, clearErrors }) => (
          <>
            <InputField
              type="text"
              placeholder="Type here."
              label="Analysis Name*"
              error={formState.errors['name']}
              registration={register('name')}
              className="border-gray-300"
              labelClassName="text-lg"
              change={() => clearErrors('name')}
              icon={<InputIcon />}
              maxLength={255}
            />
            <TextAreaField
              placeholder="Type here"
              label="Short Description"
              error={formState.errors['description']}
              registration={register('description')}
              labelClassName="text-xl"
              className="border-gray-300 h-32 align-top resize-none rounded-xl"
              change={() => clearErrors('description')}
              icon={<InputIcon className="top-1/4" />}
              maxLength={200}
            />
            <div className="flex w-full justify-center mt-4 ">
              <Button
                onClick={hideModal}
                variant="custom"
                className="mr-1 mt-6 w-52 py-3 text-black font-semibold text-lg rounded-full bg-gray-300"
              >
                Back
              </Button>
              <Button
                isLoading={addAnalysis.isLoading}
                type="submit"
                variant="custom"
                className="ml-1 mt-6 w-52 py-3 text-white font-semibold text-lg rounded-full bg-create-green "
              >
                Create
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
