import { XCircleIcon } from '@heroicons/react/solid';
import React from 'react';

import { useModalStore } from '@/stores/modal';

export const FileExistsModal = () => {
  const { hideModal, modalProps } = useModalStore();

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden rounded-2xl overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-2xl ">
          {/*content*/}
          <div className="border-0 shadow-lg relative rounded-2xl flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-1 border-b border-solid border-slate-200 rounded-t-2xl bg-[#F74B13]">
              <h3 className="text-2xl text-light-grey ml-auto">Attention</h3>
              <XCircleIcon
                className="max-w-2 fill-modal-close-bg ml-auto bg-transparent border-0 text-black opacity-40 h-10 w-10"
                onClick={hideModal}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </XCircleIcon>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-1 text-black text-md leading-relaxed mx-4">
                Uploading the new file will replace the existing file. You&#39;ll lose the
                regression and results. If you want to retain the current model, consider creating a
                new analysis.
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                className="bg-light-green text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  modalProps.fileExistsCB();
                }}
              >
                Proceed to Data Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black backdrop-blur-lg bg-opacity-60"></div>
    </>
  );
};
