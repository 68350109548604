import React from 'react';

interface callbackInterface {
  (event: React.ChangeEvent<HTMLSelectElement>): void;
}

interface Dictionary<T> {
  [Key: string]: T;
}

export const dropdownValuesMap: Dictionary<string> = {
  String: 'categorical',
  Numeric: 'numerical',
  Skip: 'descriptive',
};

export const Dropdown = ({
  selected,
  callback,
}: {
  selected: string;
  callback: callbackInterface;
}) => {
  return (
    <select
      onChange={(e) => callback(e)}
      // defaultValue={selected}
      value={dropdownValuesMap[selected]}
      className="block px-0 w-24 text-sm text-black bg-transparent border-0 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
    >
      {Object.keys(dropdownValuesMap).map((key: string, index: number) => {
        return (
          <option key={index} value={dropdownValuesMap[key]}>
            {key}
          </option>
        );
      })}
    </select>
  );
};
