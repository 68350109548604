import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    background: 'rgba(15,28,46,0.76)',
  },
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <div className="flex flex-col justify-center items-center align-middle">
        <CircularProgress color="inherit" />
        <h3 className="text-xl mt-2">Loading...</h3>
      </div>
    </Backdrop>
  );
};

export default Loader;
