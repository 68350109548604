import { useQuery } from 'react-query';

import { AssortmentListApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type CheckAnalysisDTO = {
  analysisId: string;
};

type CreatedResponse = {
  analysisExists: boolean;
};

export const checkAnalysisExists = ({ analysisId }: CheckAnalysisDTO): Promise<CreatedResponse> => {
  return axios.get(AssortmentListApi.fetchList + analysisId + '/');
};

type QueryFnType = typeof checkAnalysisExists;

type UseCheckAnalysisExists = {
  analysisId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useCheckAnalysisExists = ({ analysisId, config }: UseCheckAnalysisExists) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['checkAnalysis', analysisId],
    queryFn: () => checkAnalysisExists({ analysisId }),
  });
};
