import React, { ComponentType, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCheckAnalysisExists } from '@/hooks/useCheckAnalysisExists';

interface Params {
  analysisId: string;
}

function withAnalysisCheck<T>(Component: ComponentType<T>) {
  return (props: T) => {
    const { analysisId } = useParams<keyof Params>() as Params;

    const navigate = useNavigate();
    const location = useLocation();

    const hasAnalysis = useCheckAnalysisExists({ analysisId });

    const checkAnalysisExists = () => {
      if (hasAnalysis.isSuccess && !hasAnalysis.data?.analysisExists) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (location.state && location.state.newAnalysis) {
          return;
        } else {
          navigate('/404/', { replace: true });
        }
      }
    };
    useEffect(() => {
      checkAnalysisExists();
    }, [hasAnalysis]);

    return <Component {...props} />;
  };
}

export default withAnalysisCheck;
