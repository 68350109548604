import { Menu } from '@headlessui/react';
import * as React from 'react';

import { Link } from '@/components/Elements';
import { Avatar } from '@/components/Elements/Avatar';
import { useAuth } from '@/lib/auth';

export const DropDownMenu = () => {
  const auth = useAuth();
  const initial = auth.user?.username[0];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex flex-row justify-items-end  justify-end focus:outline-none">
        <div className="flex flex-row justify-center content-center items-center w-42">
          <Avatar initials={initial} className="w-10 h-10" />
          <div className="flex flex-col justify-center items-start max-w-sm text-ellipsis">
            <span className="text-sm text-gray-800 font-medium overflow-hidden text-start overflow-ellipsis w-24 max-w-min">
              {auth.user?.firstName ?? 'dssda'}
            </span>
            <span className="text-xs text-gray-600 overflow-ellipsis overflow-hidden text-start truncate w-24 max-w-min">
              {auth.user?.username ?? 'tds'}
            </span>
          </div>
        </div>
      </Menu.Button>
      <Menu.Items className="absolute right-0 mt-4 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none">
        <div className="px-1 py-1 ">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? 'bg-gray-300 text-gray-900' : 'text-gray-900'
                } group flex w-full items-center rounded-md px-2 py-2 text-sm focus:outline-none`}
              >
                <Link to="./users/update-password">Change Password</Link>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? 'bg-gray-300 text-red-500' : 'text-red-600'
                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                onClick={() => auth.logout()}
              >
                Logout
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};
