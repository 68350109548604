import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';

export const DeletedAnalysis = () => {
  return (
    <section className="flex flex-1 items-center h-full p-16 bg-light-blue ">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>Oops!
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">Looks like the analysis was deleted.</p>
          <Link
            rel="noopener noreferrer"
            to="/"
            className="px-8 py-3 font-semibold rounded text-white flex justify-center"
          >
            <Button
              type="submit"
              variant="custom"
              className="w-60 py-3.5 text-white font-bold text-lg rounded-sm bg-cta shadow-right"
            >
              Back to homepage
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
