import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AssortmentListApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useModalStore } from '@/stores/modal';
import { useNotificationStore } from '@/stores/notifications';

type AddAnalysisDTO = {
  name: string;
  description?: string;
};

type UseAddAnalysisOptions = {
  config?: MutationConfig<typeof addAnalysis>;
};

type CreatedResponse = {
  message: string;
  analysisId: number;
};

export const addAnalysis = ({ name, description }: AddAnalysisDTO): Promise<CreatedResponse> => {
  return axios.post(AssortmentListApi.fetchList, {
    analysis_name: name,
    short_description: description,
  });
};

export const useAddAnalysis = ({ config }: UseAddAnalysisOptions = {}) => {
  const { addNotification } = useNotificationStore();

  const { hideModal } = useModalStore();

  const navigate = useNavigate();

  return useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries('analysisList');
      hideModal();
      addNotification({
        type: 'success',
        title: 'Analysis added',
      });
      const id = data.analysisId;
      navigate(`/data-load/${id}`, { replace: true, state: { newAnalysis: true } });
    },
    ...config,
    mutationFn: addAnalysis,
  });
};
