export const AuthAPI = {
  login: 'api/auth/login/',
  resetPassword: 'api/auth/password/reset/',
  verifyResetCode: 'api/auth/validate-token/',
  refreshToken: 'api/auth/token/refresh/',
  getUser: 'api/auth/user/',
  logOut: 'api/auth/logout/',
  changePassword: 'api/auth/password/change/',
  setPassword: 'api/auth/password/reset/confirm/',
};

export const AssortmentListApi = {
  fetchList: '/',
};

export const DataLoadApi = {
  dataUpload: 'data_load/',
  fetchRows: 'data_load/detailed_data/',
};

export const RegressionAnalysisApi = {
  regressionAnalysis: 'regression_analysis/',
};

export const downloadApi = {
  downloadFile: 'download_analysis/',
};
