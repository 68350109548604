import { XIcon } from '@heroicons/react/outline';
import * as React from 'react';

import { Dialog } from '@/components/Elements';
import { useModalStore } from '@/stores/modal';

import { CreateAnalysisForm } from '../components/CreateAnalysisForm';

export const AnalysisModal = () => {
  const { hideModal } = useModalStore();

  return (
    <Dialog isOpen={true} onClose={hideModal}>
      <div className="inline-flex flex-col align-bottom bg-white rounded-lg  text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-min sm:w-full ">
        <div className="bg-dark-blue flex flex-row justify-between items-center text-xl py-3 px-4 text-white font-bold">
          Create New
          <button
            onClick={hideModal}
            className="bg-white focus:outline-none bg-opacity-40 p-1 rounded-full cursor-pointer"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        <CreateAnalysisForm />
      </div>
    </Dialog>
  );
};
