import { Navigate, Route, Routes } from 'react-router-dom';

import { ChangePassword } from './ChangePassword';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="reset-password" element={<ForgotPassword />} />
      <Route path="change-password/:uid/:token" element={<ChangePassword />} />

      <Route index element={<Login />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
