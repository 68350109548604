import { useEffect } from 'react';

import { useNotificationStore } from '@/stores/notifications';

export const useNetwork = () => {
  useEffect(() => {
    window.ononline = () => {
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Internet connection Restored.',
      });
    };
    window.onoffline = () => {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: 'No internet connection detected. Please check your internet connection.',
      });
    };
  }, []);
};
