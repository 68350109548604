import { MODAL_COMPONENTS, useModalStore } from '@/stores/modal';

export const ModalWrapper = () => {
  const { modalType } = useModalStore();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ModalComponent = MODAL_COMPONENTS[modalType];

  if (!modalType || !ModalComponent) {
    return null;
  }

  return <ModalComponent />;
};
