import { useMutation } from 'react-query';

import { DataLoadApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

import { fetchRows } from '../types';

export const fetchTopRows = ({ analysisId }: { analysisId: string }): Promise<fetchRows> => {
  return axios.get(DataLoadApi.fetchRows + `${analysisId}`, { params: { plainData: true } });
};

type fetchTopRowsOptions = {
  config?: MutationConfig<typeof fetchTopRows>;
};

export const useFetchRows = ({ config }: fetchTopRowsOptions = {}) => {
  return useMutation({
    mutationFn: fetchTopRows,
    ...config,
  });
};
