import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { Layout } from '@/components/Layout';
import { useVerifyResetCode } from '@/features/auth';

import { ChangePasswordForm } from '../components/ChangePasswordForm';
import { InvalidLink } from '../components/InvalidLink';

export const ChangePassword = () => {
  const { uid, token } = useParams();

  const verifyCode = useVerifyResetCode();

  const checkValidCode: () => void = async () => {
    const canVerify = uid !== undefined && token !== undefined;

    if (canVerify) {
      await verifyCode.mutateAsync({ uid, token });
    }
  };

  useEffect(() => {
    checkValidCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verifyCode.isLoading || verifyCode.isIdle) {
    return (
      <Layout>
        <div className="h-full w-full flex items-center justify-center">
          <Spinner size="xl" />
        </div>
      </Layout>
    );
  }

  if (verifyCode.isSuccess) {
    return (
      <Layout title="Change Password">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {verifyCode.data.valid ? <InvalidLink /> : <ChangePasswordForm uid={uid!} token={token!} />}
      </Layout>
    );
  }

  return <InvalidLink />;
};
