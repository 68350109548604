import Axios, { AxiosRequestConfig } from 'axios';
import values from 'lodash/values';

import { API_URL } from '@/config';
import { AuthAPI } from '@/lib/apiEndpoints';
import { useNotificationStore } from '@/stores/notifications';
import { keysToCamel } from '@/utils/snakeCaseConverter';
import storage, { Token } from '@/utils/storage';

export const axios = Axios.create({
  baseURL: API_URL,
});

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken(Token.accessTokenKey);

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';

  return config;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    const plainData = response.config.params?.plainData ?? false;

    if (plainData) {
      return response.data;
    }
    return keysToCamel(response.data);
  },
  async (error) => {
    if (error.response?.status == 403) {
      try {
        return await handleTokenExpired(error);
      } catch (_error) {
        storage.clearAllTokens();
        window.history.pushState({}, '', '/');
        window.location.reload();
        tokenExpired();
      }
    }
    if (!error.response) {
      genericError(error);
      return Promise.reject(error);
    } else {
      await handleGenericError(error);
    }
  }
);

const tokenExpired = () => {
  useNotificationStore.getState().addNotification({
    type: 'error',
    title: 'Logging Out',
    message: 'Your session has expired. Please log in again.',
    autoClose: true,
  });
};

const genericError = (error: any) => {
  if (error.message === 'Network Error') {
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message: 'Oops! Something went wrong please contact the Dev team.',
    });
  }
};

const handleGenericError = (error: any) => {
  let message: string = error.response?.data?.message;

  if (error.response.status !== 404) {
    message = message ?? values(error.response.data)?.[0]?.[0] ?? error.message;
  }

  const isResetPassword = error.response.config.url === AuthAPI.resetPassword;

  if (isResetPassword) {
    message = error.response.data.email.email[0];
  }

  let autoClose = true;

  const persistenceNotification = error.response.data?.persistence;
  if (persistenceNotification && JSON.parse(persistenceNotification)) {
    message = error.response.data?.regression_error;
    autoClose = false;
  }

  //Don't show arbitrary error messages
  if (message.length > 2) {
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
      autoClose: autoClose,
    });
  }

  return Promise.reject(error);
};

const handleTokenExpired = async (error: any) => {
  const tokenRefreshResponse = await refreshToken();

  storage.setToken(tokenRefreshResponse.access, Token.accessTokenKey);
  return axios(error.config);
};

type RefreshToken = {
  access: string;
};

const refreshToken = (): Promise<RefreshToken> => {
  const token = storage.getToken(Token.refreshTokenKey);
  return axios.post(AuthAPI.refreshToken, { refresh: token });
};
