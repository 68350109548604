import { HomeIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { Profile } from './Profile';

export const NavBar = () => {
  const auth = useAuth();

  const location = useLocation();

  const showHomeButton =
    location.pathname.includes('data-load') ||
    location.pathname.includes('regression-analysis') ||
    location.pathname.includes('download') ||
    location.pathname.includes('update-password');

  return (
    <div className="relative flex flex-row w-full bg-white h-16 text-center">
      <div className="flex flex-row justify-center items-center top-1/2 transform -translate-y-1/2 align-middle absolute left-6">
        <Link to="../">
          {showHomeButton && (
            <HomeIcon
              className={clsx(
                'mr-3 flex-shrink-0 h-10 w-10',
                'text-white p-2 bg-logo rounded-full'
              )}
              aria-hidden="true"
            />
          )}
        </Link>
        <h2 className="text-center text-2xl font-bold text-logo pointer-events-none">Clootrack</h2>
      </div>

      <h1 className="flex-1 place-self-center text-center text-3xl font-bold text-gray-900">
        Assortment Analysis
      </h1>
      {auth.user ? <Profile /> : null}
    </div>
  );
};
