import { useMutation } from 'react-query';

import { AssortmentListApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { AssortmentAnalysis } from '../types';

type AnalysisListDeleteDTO = {
  analysisId: string;
};

type UseDeleteAnalysisOptions = {
  config?: MutationConfig<typeof deleteAnalysis>;
};

export const deleteAnalysis = ({ analysisId }: AnalysisListDeleteDTO): Promise<void> => {
  return axios.delete(AssortmentListApi.fetchList + analysisId + '/');
};

export const useDeleteAnalysis = ({ config }: UseDeleteAnalysisOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedAnalysis) => {
      await queryClient.cancelQueries('analysisList');
      await queryClient.cancelQueries(['checkAnalysis', deletedAnalysis.analysisId]);

      const previousAnalysisList = queryClient.getQueryData<AssortmentAnalysis[]>('analysisList');

      queryClient.setQueryData(
        'analysisList',
        previousAnalysisList?.filter(
          (analysis) => analysis.analysisId !== deletedAnalysis.analysisId
        )
      );

      return { previousAnalysisList };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAnalysisList) {
        queryClient.setQueryData('analysisList', context.previousAnalysisList);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('analysisList');
      addNotification({
        type: 'success',
        title: 'Analysis Deleted',
      });
    },
    ...config,
    mutationFn: deleteAnalysis,
  });
};
