import React, { useMemo } from 'react';
import {
  Label,
  Scatter,
  XAxis,
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  Area,
  Legend,
} from 'recharts';

import { MODAL_TYPES, useModalStore } from '@/stores/modal';
import { scatterGraphColors } from '@/utils/constants';
import { decimalFormatter } from '@/utils/decimalFormatter';

import DataNotLoadedMessage from './DataNotLoadedMessage';
import { Heading } from './Heading';

const placement = { top: 10, right: 10, bottom: 10, left: 10 };

type ValueGraphProps = {
  valueGraphInfo: [];
  adjRSquare: number | null | undefined;
  dataPoints: [];
};

export const ValueGraphView = ({ valueGraphInfo, adjRSquare, dataPoints }: ValueGraphProps) => {
  const { showModal } = useModalStore();

  const isEmpty = useMemo(() => valueGraphInfo.length === 0, [valueGraphInfo]);

  const axisRange = useMemo(() => {
    const flatMap = [...valueGraphInfo.flatMap((data: any) => data)];

    const maximumY = Math.max(...flatMap.map(({ price }: { price: number }) => price));
    const maximumX = Math.max(
      ...flatMap.map(({ technicalValue }: { technicalValue: number }) => technicalValue)
    );

    const roundedMaximumY = decimalFormatter(maximumY + maximumY / 20, 3);
    const roundedMaximumX = decimalFormatter(maximumX + maximumY / 20, 3);

    return {
      x: [0, roundedMaximumX],
      y: [0, roundedMaximumY],
      area: [
        { price: 0, technicalValue: 0 },
        { price: roundedMaximumY, technicalValue: roundedMaximumX },
      ],
    };
  }, [valueGraphInfo]);

  function dataPointView(data: any) {
    showModal({
      modalType: MODAL_TYPES.DATA_POINT_MODAL,
      modalProps: { data, dataPoint: dataPoints[data.inputIndex] },
    });
  }

  return (
    <div className="flex flex-col w-full h-full flex-grow flex-1 ml-3">
      <Heading title={'Value Graph'} />
      <div className="relative flex justify-center bg-white rounded-xl shadow-lg flex-1 w-full h-full">
        {isEmpty ? (
          <DataNotLoadedMessage component_name="value graph" />
        ) : (
          <>
            <p className="absolute mx-center pb-2 text-sm font-semibold mt-1">
              Adjusted R&#178; : {decimalFormatter(adjRSquare, 3)}
            </p>
            <ResponsiveContainer width="100%" height="96%" className="mt-4" debounce={1}>
              <ComposedChart margin={placement} data={axisRange.area}>
                <XAxis
                  type="number"
                  name="Technical_Value"
                  dataKey="technicalValue"
                  domain={axisRange.x}
                >
                  <Label value="Technical Value($)" offset={-5} position="insideBottom" />
                </XAxis>
                <YAxis
                  type="number"
                  name="Price"
                  dataKey="price"
                  label={{ value: 'Price($)', angle: -90, position: 'insideLeft' }}
                  domain={axisRange.y}
                />

                <Area
                  type="monotone"
                  isAnimationActive={true}
                  dataKey="price"
                  name="Average"
                  strokeWidth={2}
                  stroke="#000"
                  fill="#E2E8F0"
                />

                {valueGraphInfo.map((dataSet: any, index: number) => (
                  <Scatter
                    key={index}
                    name={dataSet[0].brand}
                    data={dataSet}
                    isAnimationActive={false}
                    fill={scatterGraphColors[index]}
                    onClick={dataPointView}
                  />
                ))}

                <Legend
                  name="Average"
                  wrapperStyle={{
                    overflowY: 'auto',
                    maxHeight: '85%',
                    maxWidth: '30%',
                    fontSize: '0.75rem',
                    paddingLeft: '16px',
                  }}
                  iconSize={10}
                  verticalAlign="top"
                  align="right"
                  layout="vertical"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  );
};
