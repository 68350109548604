import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { DropDownMenu } from './DropDownMenu';
import { SearchBar } from './Search';

export const Profile = () => {
  const location = useLocation();

  const isAnalysisList = location.pathname == '/';
  return (
    <div className="flex flex-row top-1/2 transform -translate-y-1/2 align-middle absolute right-8 text-center text-2xl font-bold text-cta">
      {isAnalysisList && <SearchBar />}
      <DropDownMenu />
    </div>
  );
};
