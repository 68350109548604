import * as Sentry from '@sentry/react';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HashRouter as Router } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { ModalWrapper } from '@/components/Modals/ModalWrapper';
import { Notifications } from '@/components/Notifications/Notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

const ErrorFallback = () => {
  return (
    <section className="flex flex-1 items-center h-screen p-16 bg-light-blue ">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>Oops!
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Looks like something went wrong, please contact the Dev team
          </p>
          <div className="px-8 py-3 font-semibold rounded text-white flex justify-center">
            <Button
              type="submit"
              variant="custom"
              onClick={() => window.location.assign(window.location.origin)}
              className="w-60 py-3.5 text-white font-bold text-lg rounded-sm bg-cta shadow-right"
            >
              Back to homepage
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>
                <ModalWrapper />
                {children}
              </Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </Sentry.ErrorBoundary>
    </React.Suspense>
  );
};
