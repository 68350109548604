import DataNotLoadedMessage from './DataNotLoadedMessage';
import { Heading } from './Heading';

const ValueEquation = (props: any) => {
  const isEmpty = !props.technicalEquation || props.technicalEquation === '';

  return (
    <div className="value-equation w-1/2 mr-3 my-3 flex flex-col flex-1">
      <Heading title={'Technical Value Equation'} />
      <div className="p-5 h-2/3 mr-3 bg-white overflow rounded-xl shadow-lg">
        {isEmpty ? (
          <DataNotLoadedMessage component_name="equation" />
        ) : (
          <p className="p-5 leading-loose bg-white h-full overflow-auto text-gray-700">
            <span className="font-semibold">Technical Value/ ($)</span> = {props.technicalEquation}
          </p>
        )}
      </div>
    </div>
  );
};

export default ValueEquation;
