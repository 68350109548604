import React, { useState, useEffect, useMemo } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill, GoDash } from 'react-icons/all';
import { useParams } from 'react-router-dom';

import Loader from '@/components/Elements/Spinner/Backdrop';
import { ContentLayout } from '@/components/Layout';
import withAnalysisCheck from '@/providers/withAnalysisCheck';
import { decimalFormatter } from '@/utils/decimalFormatter';

import { useRegressionAnalysis, UseRegressionAnalysisOptions } from '../api/getRegressionAnalysis';
import { UpdateValueDriverParams, useUpdateRegressionAnalysis } from '../api/updateValueDriver';
import CoefficientTable from '../components/CoefficientTable';
import { ValueDriverTable } from '../components/ValueDrivers';
import ValueEquation from '../components/ValueEquation';
import { ValueGraphView } from '../components/ValueGraph';

const isDisabledColumn = (values: Record<string, any>) => {
  const isPrice = values.name === 'Price';
  const isDescriptive = values.type == 'descriptive';
  const isAdjustedNan = !values.selected && values.adjRSquareValue == null;

  return isPrice || isDescriptive || isAdjustedNan;
};

const showArrow = (
  adjRSquareValue: number | null | undefined,
  rSquare: number | null | undefined
) => {
  let component: null | JSX.Element = null;

  if (rSquare != null && adjRSquareValue != null) {
    if (adjRSquareValue > rSquare) component = <RiArrowUpSFill color="green" size="1.5rem" />;
    if (adjRSquareValue < rSquare) component = <RiArrowDownSFill color="red" size="1.5rem" />;
    if (adjRSquareValue === rSquare) component = <GoDash color="#FFA701" size="1.5rem" />;
  }

  return component;
};

const RegressionAnalysisCumulativeView = () => {
  const { analysisId } = useParams();
  const { data } = useRegressionAnalysis({
    analysisId,
  } as UseRegressionAnalysisOptions);
  const updateAnalysis = useUpdateRegressionAnalysis();

  const [showLoader, setShowLoader] = useState(true);

  const loadRegressionAnalysis = () => {
    if (data) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (updateAnalysis.isError) {
      setShowLoader(false);
    }
  }, [updateAnalysis.isError]);

  useEffect(() => {
    loadRegressionAnalysis();
  }, [data]);

  const updateRegressionAnalysis = async (event: any, id: string) => {
    setShowLoader(true);

    const apiInfo: UpdateValueDriverParams = {
      valueDriverId: id,
      selectedFlag: event.target.checked,
    };

    await updateAnalysis.mutateAsync({
      formData: apiInfo,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      analysisId: analysisId!,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: '',
        Cell: (row: any) => (
          <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm">
            {row.row.index + 1}
          </span>
        ),
        width: 30,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell: { value } }: any) => (
          <span
            data-for="value-driver"
            data-tip={value}
            style={{ cursor: 'default' }}
            className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm"
          >
            {value}
          </span>
        ),
        width: 80,
      },
      {
        Header: 'Selected',
        accessor: 'selected',
        Cell: function order(props: any) {
          return (
            <div className="flex">
              <input
                type="checkbox"
                className="accent-gray-900 focus:accent-gray-900"
                defaultChecked={props.row.values.selected}
                disabled={isDisabledColumn(props.row.values)}
                onClick={(event) => updateRegressionAnalysis(event, props.row.original.driverId)}
              />
            </div>
          );
        },
        width: 30,
      },
      {
        Header: 'Rank',
        accessor: 'rank',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm ">
              {value}
            </span>
          );
        },
        width: 40,
      },
      {
        Header: 'Adjusted R\u00B2',
        accessor: 'adjRSquareValue',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm ">
              {decimalFormatter(value, 3)}
            </span>
          );
        },
        width: 70,
      },
      {
        Header: '  ',
        accessor: '  ',
        Cell: (tableData: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm ">
              {showArrow(tableData.row.original.adjRSquareValue, data?.adjustedRSquare)}
            </span>
          );
        },
        width: 30,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm">
              {value}
            </span>
          );
        },
        width: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <ContentLayout title="">
      {showLoader ? <Loader /> : showLoader}
      <div className="w-full h-full flex flex-col">
        <div className="w-full h-auto flex" style={{ flex: 3 }}>
          <ValueDriverTable data={data?.valueDrivers ?? []} columns={columns} />

          <div className="w-1/2 ml-3">
            <ValueGraphView
              valueGraphInfo={data?.valueGraph ?? []}
              adjRSquare={data?.adjustedRSquare}
              dataPoints={data?.dataPoints ?? []}
            />
          </div>
        </div>
        <div className="w-full flex flex-row h-2/5" style={{ flex: 2 }}>
          <ValueEquation technicalEquation={data?.technicalEquation} />
          <CoefficientTable coefficientTableData={data?.coefficientTable} />
        </div>
      </div>
    </ContentLayout>
  );
};

export default withAnalysisCheck(RegressionAnalysisCumulativeView);
