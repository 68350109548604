import { AuthAPI } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';

export type LogOutDTO = {
  refresh: string;
};

export const logout = async (data: LogOutDTO) => {
  await axios.post(AuthAPI.logOut, data);
};
