import clsx from 'clsx';

type AvatarProps = {
  className?: string;
  initials?: string;
  url?: string;
};

const Image = ({ url }: { url: string }) => {
  return <img src={url} className="rounded-full" alt="avatar" />;
};

export const Avatar = ({ className = '', url = undefined, initials = 'A' }: AvatarProps) => {
  let child: JSX.Element | string;

  if (url) {
    child = <Image url={url} />;
  } else {
    child = initials;
  }

  return (
    <div
      className={clsx(
        'm-1 mr-2 w-8 h-8 relative flex justify-center items-center rounded-full bg-logo text-xl text-white uppercase',
        className
      )}
    >
      {child}
    </div>
  );
};
