import { useQuery } from 'react-query';

import { RegressionAnalysisApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { RegressionAnalysis } from '../types';

export const getRegressionAnalysis = ({
  analysisId,
}: {
  analysisId: string;
}): Promise<RegressionAnalysis> => {
  return axios.get(RegressionAnalysisApi.regressionAnalysis + `${analysisId}`);
};

type QueryFnType = typeof getRegressionAnalysis;

export type UseRegressionAnalysisOptions = {
  analysisId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useRegressionAnalysis = ({ analysisId, config }: UseRegressionAnalysisOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['regressionAnalysis', analysisId],
    queryFn: () => getRegressionAnalysis({ analysisId }),
  });
};
