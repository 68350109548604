import { useMutation } from 'react-query';

import { AuthAPI } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';

import { VerifyPasswordResponse } from '../types';

export type VerifyResetCodeDTO = {
  uid: string;
  token: string;
};

export const verifyResetCode = (data: VerifyResetCodeDTO): Promise<VerifyPasswordResponse> => {
  return axios.post(AuthAPI.verifyResetCode, data);
};

export const useVerifyResetCode = () => {
  return useMutation(verifyResetCode);
};
