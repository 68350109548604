import { Navigate, Route, Routes } from 'react-router-dom';

import RegressionAnalysisCumulativeView from './regressionAnalysisCumulativeView';

export const RegressionAnalysisRoutes = () => {
  return (
    <Routes>
      <Route path=":analysisId" element={<RegressionAnalysisCumulativeView />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
