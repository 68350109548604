import { useRoutes } from 'react-router-dom';

import { NavBar } from '@/components/NavBar/NavBar';
import { useAuth } from '@/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes(routes);

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <div className="flex flex-col flex-1">{element}</div>
    </div>
  );
};
