import create from 'zustand';

type SideBatStore = {
  disabled: boolean;
  disableLinks: () => void;
  resetLinks: () => void;
};

export const useSideBar = create<SideBatStore>((set) => ({
  disabled: false,
  disableLinks: () =>
    set({
      disabled: true,
    }),
  resetLinks: () =>
    set({
      disabled: false,
    }),
}));
