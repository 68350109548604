const DataNotLoadedMessage = (props: any) => {
  return (
    <div className="h-full rounded-lg w-full m-auto flex">
      <p className="text-xl text-gray-400 m-auto">
        Select a value to display {props.component_name}
      </p>
    </div>
  );
};

export default DataNotLoadedMessage;
