import { useQuery } from 'react-query';

import { AssortmentListApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { AssortmentAnalysis } from '../types';

export const getAnalysisList = (): Promise<AssortmentAnalysis[]> => {
  return axios.get(AssortmentListApi.fetchList);
};

type QueryFnType = typeof getAnalysisList;

type UseAnalysisListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAnalysisList = ({ config }: UseAnalysisListOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['analysisList'],
    queryFn: () => getAnalysisList(),
  });
};
