import React from 'react';

import { Button } from '@/components/Elements';
// eslint-disable-next-line import/namespace
import { MODAL_TYPES, useModalStore } from '@/stores/modal';

export const CreateNew = () => {
  const { showModal } = useModalStore();

  const showCreationModal = () => {
    showModal({ modalType: MODAL_TYPES.ANALYSIS_MODAL });
  };

  return (
    <Button
      type="submit"
      variant="custom"
      className="w-46 py-3 text-white font-bold text-base rounded-full bg-create-green"
      onClick={showCreationModal}
    >
      Create New
    </Button>
  );
};
