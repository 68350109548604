import { SearchIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { ChangeEvent } from 'react';

import { useTableSearch } from '@/stores/tableSearch';

export const SearchBar = () => {
  const { setSearchString } = useTableSearch();

  const handleChangeWithDebounce = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }, 250);

  return (
    <div className="relative w-64 sm:w-54 flex justify-center mr-6">
      <input
        type="text"
        className="border-none placeholder-gray-400 pl-6 pr-8 bg-light-grey border border-gray-300 text-gray-900 text-sm rounded-full focus:outline-none block w-full p-2.5 "
        placeholder="Search"
        onChange={handleChangeWithDebounce}
      />
      <button
        type="button"
        className="flex absolute inset-y-0 right-0 items-center pr-3 focus:outline-none"
      >
        <SearchIcon className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white" />
      </button>
    </div>
  );
};
