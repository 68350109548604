import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import {
  AuthUser,
  LoginCredentialsDTO,
  loginWithUsernameAndPassword,
  UserResponse,
  getUser,
  logout,
  LogOutDTO,
} from '@/features/auth';
import storage, { Token } from '@/utils/storage';

async function handleUserResponse(data: UserResponse) {
  const { accessToken, refreshToken, user } = data;

  storage.setToken(accessToken, Token.accessTokenKey);
  storage.setToken(refreshToken, Token.refreshTokenKey);

  return user;
}

async function loadUser() {
  if (storage.getToken(Token.accessTokenKey)) {
    const user = await getUser();
    return user;
  }
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithUsernameAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn() {
  return null;
}

async function logoutFn() {
  try {
    await apiLogout();
  } catch (e) {
    console.log('Api logout error: ', e);
  } finally {
    clearLocalStorage();
  }
}

async function apiLogout() {
  const data: LogOutDTO = { refresh: storage.getToken(Token.refreshTokenKey) };
  await logout(data);
}

function clearLocalStorage() {
  storage.clearAllTokens();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  Error | null,
  LoginCredentialsDTO,
  null | undefined
>(authConfig);
