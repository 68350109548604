import { useMutation } from 'react-query';

import { DataLoadApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

import { FileUpload } from '../types';

export interface UseMutationUploadFileVariables {
  analysisId: string;
  progressCallBack?: (progressEvent: ProgressEvent) => void;
  file: File;
}

export const uploadFile = ({
  analysisId,
  progressCallBack,
  file,
}: UseMutationUploadFileVariables): Promise<FileUpload> => {
  const formData = new FormData();
  formData.append('input_file', file);

  return axios.post(DataLoadApi.dataUpload + `${analysisId}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
  });
};

type uploadFileOptions = {
  config?: MutationConfig<typeof uploadFile>;
};

export const useFileUpload = ({ config }: uploadFileOptions = {}) => {
  // const { addNotification } = useNotificationStore();
  return useMutation({
    mutationFn: uploadFile,
    ...config,
  });
};
