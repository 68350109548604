import { nanoid } from 'nanoid';
import create from 'zustand';

export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: string;
  autoClose?: boolean;
  timeout?: number;
  visible?: boolean;
};

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
  animateOutNotification: (id: string) => void;
};

const defaultNotificationTimeout = 5000; //ms

export const useNotificationStore = create<NotificationsStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        {
          id: nanoid(),
          autoClose: true,
          visible: true,
          timeout: defaultNotificationTimeout,
          ...notification,
        },
      ],
    })),
  animateOutNotification: (id: string) =>
    set((state) => ({
      notifications: state.notifications.map((notification) => {
        if (notification.id === id) {
          notification.visible = false;
        }
        return notification;
      }),
    })),
  dismissNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}));
