import { Layout } from '@/components/Layout';

import { ResetPasswordForm } from '../components/ForgotPasswordForm';

export const ForgotPassword = () => {
  return (
    <Layout
      title="Password Reset"
      description="Forgotten your password? Enter your e-mail address below, and we'll send you and e-mail allowing you to reset it"
    >
      <ResetPasswordForm />
    </Layout>
  );
};
