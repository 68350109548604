import React, { useCallback, useMemo } from 'react';
import { HeaderGroup, useFlexLayout, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Heading } from './Heading';

const rowSize = 50; //in Px

const RenderHeader = ({ headerGroups }: { headerGroups: HeaderGroup<any>[] }): JSX.Element => {
  return (
    <>
      {headerGroups.map((headerGroup, outerIndex: number) => (
        <div {...headerGroup.getHeaderGroupProps()} key={outerIndex} className="h-12">
          {headerGroup.headers.map((column: any, index: number) => (
            <div
              {...column.getHeaderProps()}
              key={index}
              className="flex justify-center items-center border-b-0 px-2 text-sm"
            >
              {column.render('Header')}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export function ValueDriverTable({ columns, data }: any) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 80,
      maxWidth: 80,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout
  );

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className={`bg-white last:rounded-b-xl last:pb-2 first:pt-2`}
        >
          {row.cells.map((cell, innerIndex) => {
            return (
              <div
                {...cell.getCellProps()}
                className={`flex py-2 items-center justify-center px-3 bg-white`}
                key={innerIndex}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <div className="flex flex-col w-1/2 mr-3 h-max flex-grow flex-1">
      <Heading title={'Selection of Value Drivers'} />
      <div
        {...getTableProps()}
        className="flex flex-col min-h-0 max-h-full flex-1 overflow-hidden table-auto border-gray-200 rounded-xl shadow-lg"
      >
        <div className="bg-dark-blue rounded-t-xl text-white">
          <RenderHeader headerGroups={headerGroups} />
        </div>
        <div
          {...getTableBodyProps({
            style: {
              height: `100%`,
              overflowY: 'auto',
              overflowX: 'hidden',
              borderRadius: '0 0 0.75rem 0.75rem',
            },
          })}
          className="bg-white"
        >
          <AutoSizer>
            {({ height, width }) => (
              <>
                <FixedSizeList
                  height={height}
                  itemCount={rows.length}
                  itemSize={rowSize}
                  width={width}
                >
                  {RenderRow}
                </FixedSizeList>
              </>
            )}
          </AutoSizer>
          <ReactTooltip
            id="value-driver"
            place="top"
            type="dark"
            effect="solid"
            className="break-all break-words block max-w-4xl min-w-min"
            multiline={true}
            bodyMode={true}
          />
        </div>
      </div>
    </div>
  );
}
