import create from 'zustand';

import { AnalysisModal, DeleteConfirmationModal } from '@/features/analysisList';
import { FileExistsModal } from '@/features/dataLoad';
import { DataPointPopupModal } from '@/features/regressionAnalysis';

export const MODAL_TYPES = Object.freeze({
  ANALYSIS_MODAL: 'AnalysisModal',
  DELETE_ANALYSIS_MODAL: 'DeleteAnalysisModal',
  DATA_POINT_MODAL: 'DataPointModal',
  FILE_EXISTS_MODAL: 'FileExistsModal',
});

export const MODAL_COMPONENTS = {
  [MODAL_TYPES.ANALYSIS_MODAL]: AnalysisModal,
  [MODAL_TYPES.DELETE_ANALYSIS_MODAL]: DeleteConfirmationModal,
  [MODAL_TYPES.DATA_POINT_MODAL]: DataPointPopupModal,
  [MODAL_TYPES.FILE_EXISTS_MODAL]: FileExistsModal,
};

type ModalStore = {
  modalType: string | null;
  modalProps: any | Record<string, never>;
  showModal: ({ modalType, modalProps }: ModalStoreProps) => void;
  hideModal: () => void;
  updateModalProps: (modalProps: any | Record<string, never>) => void;
};

type ModalStoreProps = {
  modalType: string;
  modalProps?: any | null;
};

export const useModalStore = create<ModalStore>((set) => ({
  showModal: ({ modalType, modalProps = {} }: ModalStoreProps) =>
    set(() => ({
      modalType: modalType,
      modalProps: modalProps,
    })),
  hideModal: () =>
    set(() => ({
      modalType: null,
      modalProps: {},
    })),
  updateModalProps: (modalProps) =>
    set((state) => ({
      modalType: state.modalType,
      modalProps: modalProps,
    })),
  modalType: null,
  modalProps: {},
}));
