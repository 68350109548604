import { useMutation } from 'react-query';

import { RegressionAnalysisApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { RegressionAnalysis } from '../types';

export type UpdateValueDriverParams = {
  valueDriverId: string;
  selectedFlag: boolean;
};

export const updateRegressionAnalysis = ({
  formData,
  analysisId,
}: {
  formData: UpdateValueDriverParams;
  analysisId: string;
}): Promise<RegressionAnalysis> => {
  return axios.post(RegressionAnalysisApi.regressionAnalysis + analysisId, {
    value_driver_id: formData.valueDriverId,
    selected_flag: formData.selectedFlag,
  });
};

export const useUpdateRegressionAnalysis = () => {
  return useMutation({
    onSuccess: (data, { analysisId }) => {
      queryClient.invalidateQueries(['regressionAnalysis', analysisId]);
    },
    mutationFn: updateRegressionAnalysis,
  });
};
