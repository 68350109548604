import * as React from 'react';
import { cloneElement, useCallback, useMemo } from 'react';
import { useTable, useFlexLayout, HeaderGroup } from 'react-table';
import ReactTooltip from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { decimalFormatter } from '@/utils/decimalFormatter';

import DataNotLoadedMessage from './DataNotLoadedMessage';
import { Heading } from './Heading';

const rowSize = 50; //in Px

const RenderHeader = ({ headerGroups }: { headerGroups: HeaderGroup<any>[] }): JSX.Element => {
  return (
    <>
      {headerGroups.map((headerGroup, outerIndex: number) => (
        <div {...headerGroup.getHeaderGroupProps()} key={outerIndex} className="h-12">
          {headerGroup.headers.map((column: any, index: number) => (
            <div
              {...column.getHeaderProps()}
              key={index}
              className="flex justify-center items-center border-b-0 px-2 text-sm"
            >
              {column.render('Header')}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

function CoefficientTable(props: any) {
  const isEmpty = !props.coefficientTableData || props.coefficientTableData?.length === 0;

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        Cell: (props: any) => {
          return <div>{props.row.index + 1}</div>;
        },
        width: 24,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (props: any) => {
          return (
            <div
              data-for="table-tooltip-name"
              data-tip={props.cell.value}
              style={{
                cursor: 'default',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                wordBreak: 'break-all',
              }}
              className="text-gray-700 text-sm"
            >
              {props.cell.value}
            </div>
          );
        },
      },
      {
        Header: 'Coefficient',
        accessor: 'regressionCoefficient',
        Cell: (props: any) => {
          const value = decimalFormatter(props.cell.value, 3) ?? '';
          const component = (
            <div
              style={{
                cursor: 'default',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                wordBreak: 'break-all',
              }}
              className="text-gray-700 text-sm"
            >
              {value}
            </div>
          );

          if (value.toString().length > 6)
            return cloneElement(component, {
              'data-for': 'table-tooltip-coefficient',
              'data-tip': props.cell.value,
            });

          return component;
        },
        width: 50,
      },
      {
        Header: 'P',
        accessor: 'p',

        Cell: (props: any) => {
          const value = decimalFormatter(props.cell.value, 3) ?? '';
          const component = (
            <div
              style={{
                cursor: 'default',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                wordBreak: 'break-all',
              }}
              className="text-gray-700 text-sm"
            >
              {value}
            </div>
          );

          if (value.toString().length > 6)
            return cloneElement(component, {
              'data-for': 'table-tooltip-t-p',
              'data-tip': props.cell.value,
            });

          return component;
        },
        width: 50,
      },
      {
        Header: 't',
        accessor: 't',
        Cell: (props: any) => {
          const value = decimalFormatter(props.cell.value, 3) ?? '';
          const component = (
            <div
              style={{
                cursor: 'default',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                wordBreak: 'break-all',
              }}
              className="text-gray-700 text-sm"
            >
              {value}
            </div>
          );

          if (value.toString().length > 6)
            return cloneElement(component, {
              'data-for': 'table-tooltip-t-p',
              'data-tip': props.cell.value,
            });

          return component;
        },
        width: 50,
      },
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 80,
      maxWidth: 80,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: props.coefficientTableData ?? [],
      defaultColumn,
    },
    useFlexLayout
  );

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className={`bg-white last:rounded-b-xl last:pb-2 first:pt-2`}
        >
          {row.cells.map((cell, innerIndex) => {
            return (
              <div
                {...cell.getCellProps()}
                className={`flex py-2 items-center justify-center px-3 bg-white border-r border-gray-200`}
                key={innerIndex}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <div className="w-1/2 my-3 flex flex-col flex-1">
      <Heading title={'Coefficient Table'} />
      {isEmpty ? (
        <div className="bg-white flex flex-col h-2/3 overflow-hidden table-auto border-gray-200 rounded-xl shadow-lg">
          <DataNotLoadedMessage component_name="table" />
        </div>
      ) : (
        <div
          {...getTableProps()}
          className="flex flex-col h-2/3 overflow-hidden table-auto border-gray-200 rounded-xl shadow-lg"
        >
          <div className="bg-dark-blue rounded-t-xl text-white">
            <RenderHeader headerGroups={headerGroups} />
          </div>
          <div
            {...getTableBodyProps({
              style: {
                height: `100%`,
                overflowY: 'auto',
                overflowX: 'hidden',
                borderRadius: '0 0 0.75rem 0.75rem',
              },
            })}
            className="bg-white"
          >
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <FixedSizeList
                    height={height}
                    itemCount={rows.length}
                    itemSize={rowSize}
                    width={width}
                  >
                    {RenderRow}
                  </FixedSizeList>
                </>
              )}
            </AutoSizer>
            <ReactTooltip
              id="table-tooltip-name"
              place="top"
              type="dark"
              effect="solid"
              className="break-all break-words block max-w-4xl min-w-min"
              multiline={true}
              bodyMode={true}
            />
            <ReactTooltip
              id="table-tooltip-coefficient"
              place="top"
              type="dark"
              effect="solid"
              className="break-all break-words block max-w-4xl min-w-min"
              multiline={true}
              bodyMode={true}
            />
            <ReactTooltip
              id="table-tooltip-t-p"
              place="top"
              type="dark"
              effect="solid"
              className="break-all break-words block max-w-4xl min-w-min"
              multiline={true}
              bodyMode={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CoefficientTable;
