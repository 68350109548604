import * as React from 'react';

import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
};

const Title = ({ title }: { title: string }) => (
  <div className="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 className="mt-3 text-center text-4xl font-semibold text-gray-900">{title}</h2>
  </div>
);
const Description = ({ description }: { description: string }) => (
  <div className="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 className="mt-4 text-center text-sm font-medium" style={{ color: '#5C6D7E' }}>
      {description}
    </h2>
  </div>
);

export const Layout = ({ children, title, description }: LayoutProps) => {
  return (
    <>
      <Head title={title} />

      <div className="flex-1 bg-light-blue justify-center flex flex-col">
        {title && <Title title={title} />}
        {description && <Description description={description} />}

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-full">{children}</div>
      </div>
    </>
  );
};
