import { FolderIcon, ChartBarIcon, DocumentDownloadIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { useSideBar } from '@/stores/sideBar';

// import { useAuthorization } from '@/lib/authorization';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  disabled: boolean;
};

const classNamesForLink = ' text-gray-300 group flex items-center text-[15px] h-16 justify-center';
const disabledNavLink = 'pointer-events-none text-gray-500';

const ToolTipWrapper = (props: any) => {
  if (props.disabled) {
    return (
      <div data-for="main" data-tip="Upload Data" data-iscapture="true">
        {props.children}
      </div>
    );
  }

  return props.children;
};

const SideNavigation = () => {
  const params = useParams();
  const analysisId = params['*'];
  // const { checkAccess } = useAuthorization();

  const { disabled } = useSideBar();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const navigation = useMemo(
    () =>
      [
        { name: 'Data Load', to: `/data-load/${analysisId}`, icon: FolderIcon, disabled: false },
        {
          name: 'Regression',
          to: `/regression-analysis/${analysisId}`,
          icon: ChartBarIcon,
          disabled: disabled,
        },
        {
          name: 'Download',
          to: `/download/${analysisId}`,
          icon: DocumentDownloadIcon,
          disabled: disabled,
        },
      ].filter(Boolean) as SideNavigationItem[],
    [disabled]
  );

  return (
    <>
      {navigation.map((item, index) => (
        <>
          <ToolTipWrapper disabled={item.disabled}>
            <NavLink
              end={index === 0}
              key={item.name}
              to={item.to}
              className={({ isActive }) =>
                clsx(
                  classNamesForLink,
                  !isActive ? ' unselected' : 'bg-black text-white border-l-2 border-logo',
                  item.disabled && disabledNavLink
                )
              }
            >
              {({ isActive }) => {
                return (
                  <>
                    <item.icon
                      className={clsx(
                        'mr-3 flex-shrink-0 h-5 w-5',
                        !isActive ? ' unselected' : 'text-logo'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </>
                );
              }}
            </NavLink>
          </ToolTipWrapper>
          <ReactTooltip id="main" place="top" type="light" effect="solid" />
        </>
      ))}
    </>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const routes = ['data-load', 'regression', 'download'];
  return (
    <>
      {routes.map((term) => currentLocation.includes(term)).includes(true) ? (
        <div className=" hidden md:flex md:flex-shrink-0">
          <div className=" flex flex-col w-64">
            <div className=" flex flex-col h-0 flex-1">
              <div className=" flex-1 flex flex-col overflow-y-auto">
                <nav className=" flex-1 px-2 py-4 bg-light-black space-y-1">
                  <SideNavigation />
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div className=" h-screen flex overflow-hidden bg-light-grey">
      <Sidebar />
      <div className=" flex flex-col w-0 flex-1 overflow-hidden ">
        <div className=" flex-1 overflow-y-auto focus:outline-none flex">{children}</div>
      </div>
    </div>
  );
};
