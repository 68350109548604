import React from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { ResetPasswordDTO, useResetPassword } from '@/features/auth';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
  email: z.string().email('Please enter a valid email id for password reset mail'),
});

export const ResetPasswordForm = () => {
  const resetPassword = useResetPassword();

  const onSubmit = async (values: ResetPasswordDTO) => {
    await resetPassword.mutateAsync(values);

    showSuccessNotification();
  };

  const showSuccessNotification = () => {
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Email sent',
      message: 'Please check your email for password reset link',
    });
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <Form<ResetPasswordDTO, typeof schema>
        onSubmit={onSubmit}
        schema={schema}
        className="w-96 lg:w-[620px]"
      >
        {({ register, formState, clearErrors }) => (
          <>
            <InputField
              type="text"
              placeholder="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
              change={() => clearErrors('email')}
              className="shadow-right"
            />
            <div className="flex w-full justify-center mt-4 ">
              <Button
                isLoading={resetPassword.isLoading}
                type="submit"
                variant="custom"
                className="mt-6 w-60 py-3.5 text-white font-bold text-lg rounded-full bg-cta shadow-right"
              >
                Send reset email
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-3 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../" className="font-medium text-gray-700 hover:text-cta underline">
            or Login
          </Link>
        </div>
      </div>
    </div>
  );
};
