import React from 'react';
import { useTable } from 'react-table';

function DataLoadTable({ columns, data, getRowProps, getCellProps }: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  // Render the UI for table
  return (
    <>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-gray-200 rounded-3xl overflow-hidden">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, index: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, innerIndex: number) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          // className="border px-6 py-3 text-left text-xs font-medium text-white tracking-wider bg-light-black "
                          // {...column.getHeaderProps(column.getSortByToggleProps())}
                          {...column.getHeaderProps([
                            {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              /* @ts-ignore */
                              className: column.className,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              /* @ts-ignore */
                              style: column.style,
                            },
                          ])}
                          key={innerIndex}
                        >
                          {/* <div className="flex items-center text-white"> */}
                          {column.render('Header')}
                          {/* </div> */}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {rows.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps(getRowProps(row))} key={i}>
                        {row.cells.map((cell, index: number) => {
                          return (
                            <td
                              // Object or List of Objects
                              {...cell.getCellProps([
                                {
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  /* @ts-ignore */
                                  className: cell.column.className,
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  /* @ts-ignore */
                                  style: cell.column.style,
                                },
                                getCellProps(cell),
                              ])}
                              className="border px-6 py-4 whitespace-nowrap"
                              role="cell"
                              key={index}
                            >
                              <div className="text-sm">{cell.render('Cell')}</div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataLoadTable;
