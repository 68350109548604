import { useQuery } from 'react-query';

import { DataLoadApi } from '@/lib/apiEndpoints';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { FileExists } from '../types';

export const getFileExists = ({ analysisId }: { analysisId: string }): Promise<FileExists> => {
  return axios.get(DataLoadApi.dataUpload + `${analysisId}`);
};

type QueryFnType = typeof getFileExists;

type UseFileExistsOptions = {
  analysisId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useFileExists = ({ analysisId, config }: UseFileExistsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['fileExists', analysisId],
    queryFn: () => getFileExists({ analysisId }),
  });
};
