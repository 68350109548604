import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import React, { useState, RefObject, useRef, useEffect } from 'react';

import { MODAL_TYPES, useModalStore } from '@/stores/modal';
import { useSideBar } from '@/stores/sideBar';

import { useFileExists } from '../api/getFileExists';
import { useFileUpload } from '../api/uploadFile';
import DataLoad from '../components/DataLoad';
import Loader from '../components/Loader';

export function ListToString(ErrorList: any): string {
  let error = '';
  if (ErrorList !== undefined) {
    for (let i = 0; i < ErrorList.length; i++) {
      error += `${ErrorList[i].length > 100 ? ErrorList[i].slice(0, 100) + ' ...' : ErrorList[i]}`;
      if (i != ErrorList.length - 1) error += `\n`;
    }
  }
  return error;
}

export const BrowseFile = ({ analysisId }: { analysisId: string }) => {
  const { showModal, hideModal } = useModalStore();
  const { disableLinks, resetLinks } = useSideBar();

  const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const [fileName, setfileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [fileExists, setfileExists] = useState(false);

  const { data, isSuccess } = useFileExists({ analysisId });
  const fileUpload = useFileUpload();

  useEffect(() => {
    if (isSuccess) {
      setfileExists(data.fileExists);
      handleSidebarLinks(data.fileExists);
    }
  }, [data]);

  const handleSidebarLinks = (fileExists: boolean) => {
    if (fileExists) {
      resetLinks();
    } else {
      disableLinks();
    }
  };

  const uploadProgress = (data: any) => {
    const percent = Math.round((100 * data.loaded) / data.total);
    setProgress(percent);
  };

  const fileSelectedHandler = async () => {
    const files = inputRef.current?.files;
    if (files) {
      const name: string = files[0].name;
      setfileName(name);
      await fileUpload.mutateAsync({
        analysisId: analysisId,
        progressCallBack: uploadProgress,
        file: files[0],
      });
    }
  };

  const clickRef = () => {
    inputRef.current ? inputRef.current.click() : undefined;
  };

  const showOpenFileDlg = () => {
    if (fileExists) {
      showModal({
        modalType: MODAL_TYPES.FILE_EXISTS_MODAL,
        modalProps: {
          fileExistsCB: showFileDialog.bind(null),
        },
      });
    } else clickRef();
  };

  const showFileDialog = () => {
    hideModal();
    clickRef();
  };

  return (
    <>
      {!showTable && !fileUpload.isLoading && (
        <div className="w-full mx-auto">
          <div className="flex items-center mt-40 mb-7 relative max-w-[850px] mx-auto pt-1">
            <p className="absolute text-black text-xl ">Data Load</p>
          </div>
          <div className="max-w-[850px] mx-auto flex items-center mb-0 mt-15">
            <div className="relative w-full">
              <input
                type="file"
                hidden={true}
                ref={inputRef}
                onChange={() => {
                  fileSelectedHandler().then();
                }}
                id="selectFile"
                accept=".xlsx"
              />
              <input
                type="text"
                id="file-name"
                className="rounded-full block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Load raw data file"
                value={fileName}
                readOnly
              />
              <button
                id="browse"
                className={` ${
                  fileUpload.isIdle ? 'right-2.5' : 'right-16'
                } rounded-full text-white absolute bottom-2.5 bg-light-black hover:bg-blue-800 focus:outline-none font-medium text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                onClick={showOpenFileDlg}
              >
                Browse file
              </button>
              <CheckCircleIcon
                className={`text-green-600 h-11 w-11 absolute right-2.5 bottom-1.5 `}
                style={
                  fileUpload.isError || fileUpload.isIdle ? { display: 'none' } : { display: '' }
                }
              />
              <XCircleIcon
                className={`text-check-red h-11 w-11 absolute right-2.5 bottom-1.5`}
                style={
                  fileUpload.isSuccess || fileUpload.isIdle ? { display: 'none' } : { display: '' }
                }
              />
            </div>
            <button
              className="ml-6 text-lg drop-shadow-3xl font-medium text-white rounded-full bg-create-green hover:drop-shadow-xl px-8 p-3 pl-10 disabled:pointer-events-none disabled:bg-button-disabled
          disabled:drop-shadow-none"
              disabled={fileUpload.isError || fileUpload.isIdle}
              onClick={(e) => {
                e.preventDefault();
                setShowTable(true);
              }}
            >
              Submit
            </button>
          </div>
          <div className="flex items-center mt-7 max-w-[850px] mx-auto flex-row">
            <p className={`text-sm ${fileUpload.isSuccess ? 'text-check-green' : 'text-red-600'}`}>
              <pre>
                {fileUpload.isIdle
                  ? ''
                  : fileUpload.isSuccess
                  ? 'No Errors Found! File ready to submit'
                  : ListToString(fileUpload.error.response?.data.message ?? [''])}
              </pre>
            </p>
          </div>
        </div>
      )}
      {!showTable && fileUpload.isLoading && <Loader progress={progress} fileName={fileName} />}
      {showTable && <DataLoad analysisId={analysisId} setShowTable={setShowTable} />}
    </>
  );
};
