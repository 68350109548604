import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { AssortmentAnalysisHome } from '@/features/analysisList';
import { DeletedAnalysis } from '@/features/misc';
import { lazyImport } from '@/utils/lazyImport';

// const { DiscussionsRoutes } = lazyImport(
//   () => import('@/features/discussions'),
//   'DiscussionsRoutes'
// );
const { UserRoutes } = lazyImport(() => import('@/features/users'), 'UserRoutes');
const { DataLoadRoutes } = lazyImport(() => import('@/features/dataLoad'), 'DataLoadRoutes');
const { RegressionAnalysisRoutes } = lazyImport(
  () => import('@/features/regressionAnalysis'),
  'RegressionAnalysisRoutes'
);
const { DownloadRoutes } = lazyImport(() => import('@/features/download'), 'DownloadRoutes');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/data-load/*', element: <DataLoadRoutes /> },
      { path: '/users/*', element: <UserRoutes /> },
      { path: '/', element: <AssortmentAnalysisHome /> },
      { path: '*', element: <Navigate to="." /> },
      { path: '/download/*', element: <DownloadRoutes /> },
      { path: '/regression-analysis/*', element: <RegressionAnalysisRoutes /> },
      { path: '/404/', element: <DeletedAnalysis /> },
    ],
  },
];
